import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Props as BenefitsItemProps } from 'app/components/Benefits/Item'
import { Button } from 'app/components/Common/Button'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Calendar } from 'react-feather'

import { LanguageItem, Props as LanguageItemProps } from './LanguageItem'
import { ModalClose } from './ModalClose'
import { ModalIBE } from './ModalIBE'
import { Navigation } from './Navigation'

export interface Props {
  address?: string
  backgroundImage?: { [key: string]: any }
  benefitsDescription?: string
  benefitsLabel?: string
  benefitsChecklist?: BenefitsItemProps[]
  country?: string
  email?: string
  fax?: string
  IBEID?: number
  phone?: string
  postalCode?: string
  languageCode: string
  languagePrefix: string | null
  languagesList: LanguageItemProps[]
  logo?: string
  logoSticky?: string
  menuItems: MenuItemProps[]
  pageID?: string
  locality?: string
  region?: string
  siteName?: string
  whatsapp?: string
  variant?: Variant
}

export const Header = memo(function Header({
  address,
  backgroundImage,
  benefitsDescription,
  benefitsLabel,
  benefitsChecklist,
  country,
  email,
  fax,
  IBEID,
  phone,
  postalCode,
  languageCode,
  languagePrefix,
  languagesList,
  logo,
  logoSticky,
  menuItems,
  pageID,
  locality,
  region,
  siteName,
  whatsapp,
  variant = 'default',
}: Props) {
  const languages = languagesList.filter((t) => t.pageID === pageID)

  const activeLanguage = useRef(null)
  const [scroll, setScroll] = useState(false)
  const [navigationStatus, setNavigationStatus] = useState(false)
  const [othersLanguagesStatus, setOthersLanguagesStatus] = useState(false)
  const [modalIBEStatus, setModalIBEStatus] = useState(false)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (activeLanguage.current && !activeLanguage.current.contains(e.target)) {
      setOthersLanguagesStatus(false)
    } else {
      // @ts-ignore
      if (othersLanguagesStatus) {
        setOthersLanguagesStatus(false)
      } else {
        setOthersLanguagesStatus(true)
      }
    }
  }

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > window.innerHeight)
    }

    if (window.innerWidth > 767) {
      disableScroll.off()
    } else {
      document.body.style.overflow = 'auto'
    }

    window.addEventListener('scroll', onScroll)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      window.removeEventListener('scroll', onScroll)
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [othersLanguagesStatus])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head
          className={scroll || variant === 'compact' ? 'fixed' : ''}
          dial={6}
          row
          variant={variant}
        >
          <Hamburger
            className="hamburger-menu"
            onClick={() => {
              if (!navigationStatus) {
                setNavigationStatus(true)
                if (window.innerWidth > 767) {
                  disableScroll.on()
                } else {
                  document.body.style.overflow = 'hidden'
                }
              } else {
                setNavigationStatus(false)
              }
            }}
          >
            <div />
          </Hamburger>

          <Link to={languagePrefix ? `/${languagePrefix}` : '/'}>
            {logoSticky ? (
              <Media lessThan="ipadHorizontal">
                <Logo
                  className="logo"
                  src={logoSticky}
                  alt={siteName}
                  width="151"
                  height="120"
                />
              </Media>
            ) : null}
            {logo ? (
              <Media greaterThanOrEqual="ipadHorizontal">
                <Logo
                  className="logo"
                  src={scroll || variant === 'compact' ? logoSticky : logo}
                  alt={siteName}
                  width="151"
                  height="120"
                />
              </Media>
            ) : null}
          </Link>

          {phone ? (
            <Phone
              className="header-phone"
              href={`tel:${phone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Mobile Phone',
                    })
                }
              }}
            >
              {phone}
            </Phone>
          ) : null}

          {whatsapp ? (
            <WA
              className="header-whatsapp"
              aria-label="Whatsapp"
              href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
              rel="noreferrer"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path d="M11.665,9.588c-.2-.1-1.178-.578-1.36-.644s-.315-.1-.449.1-.514.643-.629.775-.233.14-.431.05a5.411,5.411,0,0,1-1.6-.99A6.048,6.048,0,0,1,6.088,7.5c-.116-.2-.013-.31.087-.41s.2-.23.3-.349a1.69,1.69,0,0,0,.2-.331.366.366,0,0,0-.017-.349c-.05-.1-.448-1.08-.615-1.471s-.325-.34-.448-.34-.247-.01-.381-.01a.752.752,0,0,0-.531.239,2.226,2.226,0,0,0-.7,1.65A3.9,3.9,0,0,0,4.8,8.179a8.889,8.889,0,0,0,3.4,2.99c.476.2.847.32,1.136.419a2.758,2.758,0,0,0,1.253.081,2.052,2.052,0,0,0,1.344-.951,1.65,1.65,0,0,0,.12-.95c-.049-.09-.18-.14-.38-.23M8.041,14.5H8.03a6.669,6.669,0,0,1-3.37-.92l-.24-.143-2.5.65.67-2.43-.159-.25a6.612,6.612,0,0,1,10.3-8.157,6.525,6.525,0,0,1,1.939,4.66A6.62,6.62,0,0,1,8.045,14.5M13.68,2.3A8.1,8.1,0,0,0,8.03,0a7.926,7.926,0,0,0-6.9,11.892L0,16l4.223-1.1a8.041,8.041,0,0,0,3.807.965h0A7.96,7.96,0,0,0,16,7.933a7.863,7.863,0,0,0-2.33-5.607" />
              </svg>
            </WA>
          ) : null}

          <Languages className="header-langs" ref={activeLanguage}>
            <LanguageActive
              className={othersLanguagesStatus ? 'open' : undefined}
              dial={4}
              row
            >
              {languages
                .filter((t) => t.languagePrefix === languagePrefix)
                .map((item, index) => (
                  <LanguageItem key={index} {...item} />
                ))}
              <svg width="9" height="7" viewBox="0 0 9 7">
                <path d="M4.5,0,9,7H0Z" />
              </svg>
            </LanguageActive>
            <OthersLanguages
              className={othersLanguagesStatus ? 'open' : undefined}
            >
              {languagesList
                .filter((t) => t.languagePrefix !== languagePrefix)
                .map((item, index) => (
                  <LanguageItem key={index} {...item} />
                ))}
            </OthersLanguages>
          </Languages>

          <Media greaterThan="tabletMedium">
            <CTA
              arrow
              label={useVocabularyData('book', languageCode)}
              onClick={() => {
                if (!modalIBEStatus) {
                  setModalIBEStatus(true)
                  disableScroll.on()
                } else {
                  setModalIBEStatus(false)
                  disableScroll.off()
                }
              }}
            />
          </Media>

          <Media between={['ipadVertical', 'ipadHorizontal']}>
            <CTA
              arrow
              URL={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
                languagePrefix ? languagePrefix : 'en'
              }`}
              label={useVocabularyData('book', languageCode)}
              rel="noopener"
              target="_blank"
            />
          </Media>
        </Head>

        {IBEID ? (
          <Media lessThan="desktopSmall">
            <BookingCTA
              href={`https://be.bookingexpert.it/book/simple/step1?hotel=${IBEID}&lang=${
                languagePrefix ? languagePrefix : 'en'
              }`}
              rel="noopener"
              target="_blank"
            >
              <Calendar />
              {useVocabularyData('book', languageCode)}
            </BookingCTA>
          </Media>
        ) : null}

        <Modal className={navigationStatus ? 'open' : ''}>
          <ModalClose
            className="close"
            onClick={() => {
              if (window.innerWidth > 767) {
                disableScroll.off()
              } else {
                document.body.style.overflow = 'auto'
              }
              setNavigationStatus(false)
            }}
          />
          <Navigation
            address={address}
            backgroundImage={backgroundImage}
            country={country}
            email={email}
            fax={fax}
            IBEID={IBEID}
            languageCode={languageCode}
            languagePrefix={languagePrefix || 'en'}
            logo={logo}
            locality={locality}
            menuItems={menuItems}
            navigationStatus={navigationStatus}
            phone={phone}
            postalCode={postalCode}
            region={region}
            siteName={siteName}
            whatsapp={whatsapp}
          />
        </Modal>

        <Media greaterThanOrEqual="ipadVertical">
          <Modal className={modalIBEStatus ? 'open' : ''}>
            <ModalClose
              onClick={() => {
                disableScroll.off()
                setModalIBEStatus(false)
              }}
              variant="left"
            />
            <ModalIBE
              IBEID={IBEID}
              languageCode={languageCode}
              languagePrefix={languagePrefix || 'en'}
              benefitsDescription={benefitsDescription}
              benefitsLabel={benefitsLabel}
              benefitsChecklist={benefitsChecklist}
              modalStatus={modalIBEStatus}
            />
          </Modal>
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const HeaderFixedStyle = css`
  background: ${theme.colors.variants.neutralLight4};
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 60px;
  margin-top: 0;
  position: fixed;
  top: 0;
  left: 0;
  .logo {
    height: 50px;
    margin-top: 0.5625rem;
  }
  .hamburger-menu {
    &:before,
    &:after {
      background: ${theme.colors.variants.neutralDark4};
    }
    > div {
      background: ${theme.colors.variants.neutralDark4};
    }
  }
  .header-phone {
    color: ${theme.colors.variants.neutralDark4};
  }
  .header-whatsapp {
    svg {
      fill: ${theme.colors.variants.neutralDark4};
    }

    &:after {
      content: none;
    }
    &:before {
      background: ${theme.colors.variants.neutralLight2};
      opacity: 1;
      top: 0;
      bottom: 0;
      height: 100%;
      transform: none;
    }

    @media (max-width: 767px) {
      &:before {
        right: 0;
        left: auto;
      }
    }
  }
  .header-langs {
    margin-right: 1rem;
    > div {
      &:first-of-type {
        &:before {
          opacity: 1;
        }
      }
      &:last-of-type {
        width: calc(100% + 1rem);
        background: ${theme.colors.variants.neutralLight4};
        top: 1.1875rem;
      }
      a {
        color: ${theme.colors.variants.neutralDark4};
      }
    }
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  margin-top: 2.25rem;
  padding: 0 2.25rem;
  position: relative;
  &.fixed {
    ${HeaderFixedStyle}
  }

  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return css`
          &.fixed {
            animation: fadeInTop 0.3s;
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    ${HeaderFixedStyle}
    animation: none !important;
    height: 72px;
    padding: 0 0 0 22px;
    .logo {
      height: 3.125rem !important;
      margin-top: 15px !important;
    }
  }

  @keyframes fadeInTop {
    0% {
      top: -3.75rem;
    }
    100% {
      top: 0;
    }
  }
`

const Logo = styled.img`
  width: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`

const Hamburger = styled.div`
  cursor: pointer;
  width: 36px;
  margin-right: auto;
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 2px;
  }
  &:hover {
    > div {
      width: 100%;
    }
  }
  > div {
    width: 17px;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 2px;
    margin: 12px auto;
    transition: 0.3s ease;
  }

  @media (max-width: 767px) {
    width: 30px;
    > div {
      width: 100%;
      margin: 5px auto;
    }
  }
`

const WA = styled.a`
  position: relative;
  height: 70px;
  width: 3.5rem;

  @media (max-width: 1023px) {
    display: none;
  }

  &:before,
  &:after {
    content: '';
    height: 1.5rem;
    width: 0.0625rem;
    opacity: 0.2;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }

  &:hover {
    svg {
      opacity: 0.5;
    }
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 1rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    transition: 0.3s ease-in-out;
  }
`

const Phone = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  margin-right: 1.875rem;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Languages = styled.div`
  margin-right: 1.875rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    margin-right: 0 !important;
    position: absolute;
    top: 26px;
    left: 70px;
  }
`

const LanguageActive = styled(FlexBox)`
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 70px;
    margin-right: 1rem;
    opacity: 0;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
  }
  &.open {
    svg {
      transform: scaleY(1) translateY(-1px);
    }
  }
  svg {
    fill: ${({ theme }) => theme.colors.variants.primaryDark1};
    margin-left: 0.75rem;
    transform: scaleY(-1);
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    &:before {
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight0} !important;
      height: 1.5rem;
      margin-right: 1.125rem;
    }

    svg {
      width: auto;
      height: 5px;
      margin-left: 0.375rem;
    }
  }
`

const OthersLanguages = styled.div`
  width: 2.125rem;
  opacity: 0;
  padding: 0 1rem;
  position: absolute;
  top: 1.875rem;
  left: 0;
  transform: translateY(2.25rem);
  transition: 0.3s ease-in-out;
  visibility: hidden;
  z-index: -1;
  &.open {
    opacity: 1;
    transform: translateY(1.5rem);
    visibility: visible;
    z-index: 1;
  }
  a {
    display: block;
    margin-bottom: 0.5625rem;
  }
`

const BookingCTA = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  height: 72px;
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding: 0 19px;
  position: fixed;
  top: 0;
  right: 0;
  text-align: center;

  svg {
    display: block;
    width: auto;
    height: 18px;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 0 auto 0.375rem;
  }
`

const CTA = styled(Button)`
  margin-top: 0;

  @media (max-width: 1023px) {
    margin-top: 0;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    svg {
      display: none;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }

  .close {
    top: 18px;
    right: auto;
    left: 18px;
  }
`

interface HeadProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
